import dynamic from 'next/dynamic'

/**
 * This file let you add new content types to the master data magento-page-builder component.
 */
const LeadFormNewsletterCompact = dynamic(() =>
	import(
		'@/components/ui/particles/rich-content/renderers/magento-pagebuilder/content-types/lead-form/content-types/newsletter/compact'
	)
)

const leadFormCustomContentTypes = {
	'newsletter-compact': LeadFormNewsletterCompact,
}

export default leadFormCustomContentTypes
